import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LocalizedLink from './LocalizedLink';

const Layout = styled.div`
    border-bottom: 1px solid #1f5fb7;
    border-top: 1px solid #1f5fb7;
    position: relative;
    margin: 5% 0%;
`;

const Wrapper = styled.div`
    display: center;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    height: 60px;
    text-align: center;
`;

const Items = styled.ul`
    padding-left: 0px;
    list-style: none;
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
`;

const ListItem = styled.li`
    display: inline-block;
`;

const Category = styled.div`
    display: inline-block;
    color: ${(props) => (props.isActive ? '#FFFFFF' : '#B4D5FF')};
    font-weight:300;
    margin-right: 35px;

    &:hover{
        color:white;
    }
`;


const ListItems = (props) => {
    return (
        <ListItem>
            <LocalizedLink to={props.link}>
                <Category isActive={props.isActive}>
                    {props.category}
                </Category>
            </LocalizedLink>
        </ListItem>
    );
};

ListItems.propTypes = {
    link: PropTypes.string,
    isActive: PropTypes.bool,
    category: PropTypes.string
};

const HorizontalMenu = ({list}) => {

    const category = list.map(i=> (<ListItems key={i.url}
        isActive={i.isActive}
        category={i.category}
        link={i.url}/>));

    return (
        <Layout>
            <Wrapper>
                <Items>
                    {category}
                </Items>
            </Wrapper>
        </Layout>
    );
};

HorizontalMenu.defaultProps = {list: []};

HorizontalMenu.propTypes = {list: PropTypes.arrayOf(PropTypes.object)};

export default React.memo(HorizontalMenu);
