import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
// Layout component
import Layout from '../components/layouts/Layout';
import HorizontalMenu from '../components/HorizontalMenu';
import './privacy-policy.css';

const IndexPage = () => {
    const { t } = useTranslation('TermOfUse');

    return (
        <React.Fragment>
            <Helmet defer={false}>
                <title>{t('META_TITLE_TERM_OF_USE')}</title>
                <meta name="description"
                    content={t('META_DESCRIPTION_TERM_OF_USE')}/>
            </Helmet>
            <Layout>
                <div className='wemap-page-index'>
                    <h1 style={{ fontSize: '5vh' }}
                        className='wemap-page-index-title'
                        dangerouslySetInnerHTML={{__html: t('TITLE')}}></h1>
                    <HorizontalMenu
                        list={[
                            {
                                url: '/privacy-policy/',
                                category: t('PRIVACY_POLICY')
                            },
                            {
                                url: '/terms-of-use/',
                                isActive: true,
                                category: t('TERM_OF_USE')
                            },
                            {
                                url: '/terms-of-use-pro/',
                                category: t('TERM_OF_USE_PRO')
                            },
                            {
                                url: '/privacy-policy-rueil/',
                                category: t('PRIVACY_POLICY_RUEIL')
                            },
                            {
                                url: '/privacy-policy-mission-herault/',
                                category: t('PRIVACY_POLICY_MISSION_HERAULT')
                            },
                            {
                                url: '/privacy-policy-paris-region-aventures/',
                                category: t('PRIVACY_POLICY_PARIS_REGION_AVENTURES')
                            },
                            {
                                url: '/privacy-policy-colmar-aventure/',
                                category: t('PRIVACY_POLICY_COLMAR_AVENTURE')
                            },
                            {
                                url: '/privacy-policy-mont-saint-michel-aventures/',
                                category: t('PRIVACY_POLICY_MONT_SAINT_MICHEL_AVENTURE')
                            }
                        ]}
                    />

                    <h2>{t('TITLE_APPLICABLES')}</h2>

                    <p dangerouslySetInnerHTML={{__html: t('DESCRIPTION_APPLICABLES')}}></p>

                    <h2>{t('TITLE_PREAMBULE')}</h2>

                    <p dangerouslySetInnerHTML={{__html: t('DESCRIPTION_PREAMBULE')}}></p>

                    <h2>{t('TITLE_DEFINITION')}</h2>

                    <p dangerouslySetInnerHTML={{__html: t('DESCRIPTION_DEFINITION')}}></p>

                    <h2>{t('TITLE_MODIFICATIONS')}</h2>

                    <p dangerouslySetInnerHTML={{__html: t('DESCRIPTION_MODIFICATIONS')}}></p>


                    <h2>{t('TITLE_SERVICES_WEMAP')}</h2>

                    <p dangerouslySetInnerHTML={{__html: t('DESCRIPTION_SERVICES_WEMAP')}}></p>

                    <p dangerouslySetInnerHTML={{__html: t('DESCRIPTION_ACCESS')}}></p>

                    <h2>{t('TITLE_CREATION')}</h2>

                    <p dangerouslySetInnerHTML={{__html: t('DESCRIPTION_CREATION')}}></p>

                    <p dangerouslySetInnerHTML={{__html: t('TITLE_GENERALITIES')}}></p>


                    <h2>{t('TITLE_DISPONIBILITE')}</h2>

                    <p dangerouslySetInnerHTML={{__html: t('DESCRIPTION_DISPONIBILITE')}}></p>


                    <h2>{t('TITLE_LOCALISATION')}</h2>

                    <p dangerouslySetInnerHTML={{__html: t('DESCRIPTION_LOCALISATION')}}></p>

                    <h2>{t('TITLE_UTILISATION_APPLICATIONS')}</h2>

                    <p dangerouslySetInnerHTML={{__html: t('DESCRIPTION_UTILISATION_APPLICATIONS')}}></p>

                    <p dangerouslySetInnerHTML={{__html: t('DESCRIPTION_PUBLICATION')}}></p>

                    <p dangerouslySetInnerHTML={{__html: t('DESCRIPTION_CONTENT')}}></p>

                    <p dangerouslySetInnerHTML={{__html: t('DESCRIPTION_UTILISATION')}}></p>

                    <p dangerouslySetInnerHTML={{__html: t('DESCRIPTION_DIFFUSION')}}></p>

                    <p dangerouslySetInnerHTML={{__html: t('DESCRIPTION_DIFFUSION_CONTENU')}}></p>

                    <p dangerouslySetInnerHTML={{__html: t('DESCRIPTION_DIFFUSION_CONTENU')}}></p>

                    <p dangerouslySetInnerHTML={{__html: t('DESCRIPTION_ERREURS')}}></p>

                    <p dangerouslySetInnerHTML={{__html: t('DESCRIPTION_APPS_PUB')}}></p>

                    <p dangerouslySetInnerHTML={{__html: t('DESCRIPTION_OTHER_USERS')}}></p>

                    <p dangerouslySetInnerHTML={{__html: t('DESCRIPTION_RESPECT')}}></p>

                    <p dangerouslySetInnerHTML={{__html: t('TITLE_UTILISATION')}}></p>

                    <p dangerouslySetInnerHTML={{__html: t('TITLE_SUSPENSION')}}></p>

                    <h2>{t('TITLE_PROPRIETE_INTELLECTUELLE')}</h2>

                    <p dangerouslySetInnerHTML={{__html: t('DESCRIPTION_PROPRIETE_INTELLECTUELLE')}}></p>

                    <p dangerouslySetInnerHTML={{__html: t('DESCRIPTION_LICENCE_UTILISATION')}}></p>

                    <h2>{t('TITLE_RESPONSABILITY')}</h2>

                    <h3 dangerouslySetInnerHTML={{__html: t('SECOND_TITLE_RESPONSABILITY')}}></h3>

                    <p dangerouslySetInnerHTML={{__html: t('DESCRIPTION_RESPONSABILITY')}}></p>

                    <h3 dangerouslySetInnerHTML={{__html: t('TITLE_RESPONSABILITY_USERS')}}></h3>

                    <p dangerouslySetInnerHTML={{__html: t('DESCRIPTION_RESPONSABILITY_USERS')}}></p>


                    <h2>{t('TITLE_SITES_TIERS')}</h2>

                    <p dangerouslySetInnerHTML={{__html: t('DESCRIPTION_SITES_TIERS')}}></p>

                    <h2>{t('TITLE_POLITIQUE_CONFIDENTIALITE')}</h2>

                    <p dangerouslySetInnerHTML={{__html: t('DESCRIPTION_POLITIQUE_CONFIDENTIALITE')}}></p>

                    <h2>{t('TITLE_RESILIATION')}</h2>

                    <p dangerouslySetInnerHTML={{__html: t('DESCRIPTION_RESILIATION')}}></p>


                    <h2>{t('TITLE_STIPULATIONS_GENERAL')}</h2>

                    <p dangerouslySetInnerHTML={{__html: t('DESCRIPTION_STIPULATIONS_GENERAL')}}></p>

                    <h2>{t('TITLE_COMPETENCE_JURIDICTIONNELLE')}</h2>

                    <p dangerouslySetInnerHTML={{__html: t('DESCRIPTION_COMPETENCE_JURIDICTIONNELLE')}}></p>


                </div>
            </Layout>
        </React.Fragment>
    );
};

IndexPage.propTypes = {data: PropTypes.object};

export default IndexPage;
