// eslint-disable-next-line no-unused-vars
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import i18n from '../../locales/i18n';

import { withPageContext } from '../../../pageContext';
import RootLayout from './RootLayout';
import LayoutTemplate from './LayoutTemplate';
import { getAlternatePath } from './../../components/LocalizedLink';

const MainLayoutContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const MainLayoutContentContainer = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 1;
`;

class Layout extends RootLayout {
    render() {
        const { children, pageContext } = this.props;
        const alternate = getAlternatePath(i18n.language, pageContext.originalPath);

        return (
            <I18nextProvider i18n={i18n}>
                <MainLayoutContainer>
                    <Helmet>
                        <html lang={i18n.language} />
                        {alternate.map((lang) => (
                            <link rel="alternate"
                                hreflang={lang.hreflang}
                                href={`https://getwemap.com${lang.href}`} />
                        ))}
                    </Helmet>

                    <MainLayoutContentContainer id='outer-container'>
                        <LayoutTemplate>
                            {children}
                        </LayoutTemplate>
                    </MainLayoutContentContainer>
                </MainLayoutContainer>
            </I18nextProvider>
        );
    }
}

export default withPageContext(Layout);
